<template>
    <WatchForUnsavedChanges ref="watch" v-model="user" v-slot="{ hasChanges }">
        <Spinner v-if="!user.id"></Spinner>
        <SidePanel v-else :title="$t('main.user')" @close="$router.push({ name: 'settings.users' })">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <fieldset :disabled="!canEdit || user.deleted_at">
                <div class="form-group">
                    <label class="form-label" for="name">{{ $t('users.name') }}</label>
                    <input v-model="user.name" class="form-input" id="name" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="location">{{ $t('main.location') }}</label>
                    <select v-model="user.location_id" class="form-select" id="location">
                        <option v-for="location in locations" :key="location.id" :value="location.id">
                            {{ location.location }}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="form-label" for="email">{{ $t('users.email') }}</label>
                    <input v-model="user.email" type="email" class="form-input" id="email" :readonly="!canEditEmail" />
                </div>

                <div class="form-group">
                    <button class="btn" @click="resetPassword()">{{ $t('main.reset') }}</button>
                </div>

                <div class="form-group">
                    <label class="form-label" for="role">{{ $t('users.role') }}</label>
                    <select v-model="user.role" class="form-select" id="role">
                        <option v-for="role in roles" :key="role" :value="role">{{ $t(`user_roles.${role}`) }}</option>
                    </select>
                </div>

                <details class="accordion mb-4">
                    <summary class="accordion-header">
                        <i class="fas fa-chevron-right mr-2"></i>
                        <span>{{ $t('users.has_permission_for_locations') }}</span>
                    </summary>
                    <div class="accordion-body" v-if="user.locations">
                        <label class="form-checkbox text-bold mb-0">
                            <input type="checkbox" v-model="allLocationsSelected" @change="selectAllLocations()" />
                            <i class="form-icon"></i>
                            <span>{{ $t('main.select_all') }}</span>
                        </label>

                        <div style="max-height: 200px; overflow: auto">
                            <div v-for="location in user.locations" :key="location.id">
                                <label class="form-checkbox my-0" v-if="user.location_id == location.id">
                                    <input type="checkbox" :checked="true" disabled />
                                    <i class="form-icon"></i>
                                    <span>{{ location.location }}</span>
                                </label>
                                <label class="form-checkbox my-0" v-else>
                                    <input type="checkbox" v-model="location.selected" />
                                    <i class="form-icon"></i>
                                    <span>{{ location.location }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </details>

                <div class="flex-space" v-show="!user.deleted_at">
                    <SaveButton @click="save()" class="mr-2" :disabled="!hasChanges" />

                    <button class="btn" v-if="user.activated === false" @click="resendInvitationEmail()"
                        :class="{ loading: sendingInvitationEmail }">
                        {{ $t('auth.resend_invitation_email') }}
                    </button>

                    <button class="btn btn-error ml-auto" @click="deleteUser()">{{ $t('main.delete') }}</button>
                </div>
            </fieldset>

            <div class="flex-space" v-if="user.deleted_at">
                <button class="btn" @click="restoreUser()">{{ $t('users.restore') }}</button>
                <button class="btn btn-error" @click="permanentDelete()">{{ $t('users.permanent_delete') }}</button>
            </div>
        </SidePanel>
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'

export default {
    props: ['locations'],
    emits: ['refresh'],

    components: { SidePanel },

    data() {
        return {
            errors: null,

            user: {},
            allLocationsSelected: false,

            id: this.$route.params.id,
            roles: ['ReadOnly', 'User', 'Manager'],
            canEdit: this.$store.getters.can('Update users'),

            sendingInvitationEmail: false,
            canEditEmail: this.$store.state.hasAllAbilities,
        }
    },

    computed: {
        selectedLocations() {
            return this.user.locations.filter(location => location.selected)
        },
    },

    async mounted() {
        const { data } = await this.$axios.get(`users/${this.id}`)

        if (this.$store.state.user.role === 'Manager') {
            this.canEdit = this.$store.state.user.location_id === data.location_id
        } else {
            this.roles.push('SuperAdmin')
        }

        if (
            this.$store.state.hasAllAbilities ||
            this.$store.state.user.role === 'PlatformManager' ||
            this.$store.state.user.role === 'LicenseAdmin'
        ) {
            this.roles.push('LicenseAdmin')
        }

        if (this.$store.state.user.properties.use_kiosks) {
            this.roles.push('Kiosk')
        }

        const role = data.roles[0]?.name

        if (!this.roles.includes(role)) this.roles.push(role)

        this.user = data
        this.user.role = role
        this.allLocationsSelected = this.selectedLocations.length === this.user.locations.length
    },

    methods: {
        async save() {
            this.errors = null
            const locations = this.selectedLocations

            try {
                await this.$axios.patch(`users/${this.id}`, { ...this.user, locations })

                this.$emit('refresh')
                window.toast(this.$root.$t('i.update', { i: this.user.name }))
                this.$refs.watch?.init()
            } catch ({ response }) {
                this.errors = response
            }
        },

        restoreUser() {
            this.$swal({
                showCancelButton: true,
                confirmButtonColor: '#e89543',
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('users.restore'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.post(`users/${this.id}/restore`).then(() => {
                        this.$emit('refresh')
                        this.user.deleted_at = null
                        this.$refs.watch?.init()
                    })
                }
            })
        },

        resendInvitationEmail() {
            this.sendingInvitationEmail = true

            this.$axios.post(`users/${this.id}/resend_invitation`).then(() => {
                this.sendingInvitationEmail = false
                window.toast(this.$root.$t('auth.sent_invitation_email'))
            })
        },

        selectAllLocations() {
            this.user.locations.forEach(u => (u.selected = this.allLocationsSelected))
        },

        deleteUser() {
            this.$swal({
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: () => {
                    return this.$axios
                        .delete(`users/${this.id}`)
                        .then(() => this.user)
                        .catch(({ response }) => {
                            return this.$swal.showValidationMessage(response.data.error)
                        })
                },
            }).then(({ value }) => {
                if (!value) return

                this.$emit('refresh')
                this.$router.push({ name: 'settings.users' })
                window.toast(this.$root.$t('i.delete', { i: value.name }))
            })
        },

        permanentDelete() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.post(`users/${this.id}/permanent_delete`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.users' })
                        window.toast(this.$root.$t('i.delete', { i: this.user.name }))
                    })
                }
            })
        },

        resetPassword() {
            const generatePassword = (() => {
                const charset = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789'
                let pw = ''

                for (var i = 0, n = charset.length; i < 6; ++i) {
                    pw += charset.charAt(Math.floor(Math.random() * n))
                }

                return pw
            })()

            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('users.create_temporary_password'),
                confirmButtonText: this.$t('main.reset'),
                showLoaderOnConfirm: true,
                cancelButtonText: this.$t('main.cancel'),

                preConfirm: () => {
                    return this.$axios.post(`users/${this.id}/reset_password`, { password: generatePassword })
                },
            }).then(result => {
                if (result.value) {
                    return this.$swal({
                        title: generatePassword,
                        text: this.$t('users.keep_password_safe'),
                    })
                }
            })
        },
    },
}
</script>
