<template>
    <div class="responsive-filters">
        <button class="btn toggle-filters-btn" :class="{ badge: params.length }" :data-badge="params.length"
            @click="open = true">
            <i class="fa-regular fa-filter"></i>
        </button>
        <div class="filters-panel" :class="{ open }">
            <div class="filters-panel-backdrop" @click="open = false"></div>
            <div class="filters-panel-content">
                <div class="filters-panel-header">
                    <h2 class="m-0">{{ $t('table.filters') }}</h2>
                    <i class="c-hand fa-regular fa-xmark fa-2xl mr-2" @click="open = false"></i>
                </div>
                <slot></slot>

                <div class="show-results d-flex">
                    <button class="btn btn-primary mr-2" :class="{ loading }" @click="open = false">
                        <span>{{ $t('table.show_results', paginator.total) }}</span>
                    </button>
                    <button class="btn" :class="{ ghost: !params.length }" @click="$emit('clearParameters')">
                        <span>{{ $t('table.clear_filters') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        paginator: {
            type: Object,
            required: true,
        },
        params: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },

    emits: ['clearParameters'],

    data: () => ({ open: false }),
}
</script>
