<template>
    <Transition name="fade" appear>
        <div class="modal-custom" ref="bg">
            <div class="modal-wrapper" v-click-outside="close">
                <div class="modal-header">
                    <div class="flex-start">
                        <h4 class="text-bold mb-0 mr-2">{{ title }}</h4>
                        <slot name="subtitle"></slot>
                    </div>
                    <button v-if="onClose" class="btn btn-clear" @click="close()"></button>
                </div>

                <div class="modal-content">
                    <slot></slot>
                </div>

                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        title: String,
        onClose: [Function, Array]
    },

    mounted() {
        document.body.classList.add('no-scroll')
    },

    unmounted() {
        document.body.classList.remove('no-scroll')
    },

    methods: {
        close() {
            if (!this.onClose) return

            // we don't want to close the current modal when
            // another modal, sidepanel, menu, vselect or datepicker is open
            if (this.$el.querySelector('.menu') !== null ||
                this.$el.querySelector('.modal-custom') !== null ||
                this.$el.querySelector('.sidepanel-wrapper') !== null ||
                this.$el.querySelector('.vs__dropdown-menu') !== null ||
                this.$el.querySelector('.dp__outer_menu_wrap') !== null) {
                return
            }

            this.$emit('close')
        },
    },
}
</script>
