<template>
    <div class="box" :id="mapId"></div>
</template>

<script>
import * as L from 'leaflet'
import 'leaflet.markercluster'
import { generateUniqueId } from '~/utils'

export default {
    props: ['uploads', 'snap_id'],

    data() {
        return {
            map: null,
            markers: null,
            mapId: `map-${generateUniqueId()}`,
        }
    },

    watch: { uploads: 'uploadsChanged', snap_id: 'moveToSnapId' },

    mounted() {
        this.renderMap()
        this.addMarkersToMap()
    },

    methods: {
        renderMap() {
            const layer = L.tileLayer(window.config.openStreetMap.skin, {
                attribution: window.config.openStreetMap.copyright,
                maxZoom: 19,
            })

            this.map = L.map(this.mapId, {
                zoom: 2,
                minZoom: 2,
                layers: [layer],
                center: [51.505, -0.09],
                touchZoom: true,
            })

            this.map.scrollWheelZoom.disable()
        },

        uploadsChanged(newValue, oldValue) {
            if (newValue.length === oldValue.length) return

            this.addMarkersToMap()
        },

        moveToSnapId() {
            const marker = this.markers.getLayers().find(l => l.snap_id == this.snap_id)

            if (!marker) {
                this.map.fitBounds(this.markers.getBounds())
                return
            }

            this.markers.zoomToShowLayer(marker, () => { marker.openPopup() })
        },

        addMarkersToMap() {
            if (this.uploads.length === 0) return
            if (this.markers) this.markers.clearLayers()

            this.markers = L.markerClusterGroup({})

            const iconOptions = { iconSize: [36, 36], iconAnchor: [17, 35], popupAnchor: [0, -30] }

            const redIcon = L.icon({ iconUrl: '/images/markers/marker-icon-red.png', ...iconOptions })
            const blueIcon = L.icon({ iconUrl: '/images/markers/marker-icon-blue.png', ...iconOptions, })

            this.uploads.forEach(item => {
                const createdAt = dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')
                const address = item.geocoding?.addresses[0]?.address.freeformAddress

                if (item.latitude !== null) {
                    const icon = item.has_damage ? redIcon : blueIcon
                    const marker = L.marker(L.latLng(item.latitude, item.longitude), { icon })

                    marker.snap_id = item.id

                    const popup = L.popup({ maxWidth: 250 }).setContent(
                        `<a href="/files/${item.file_id}" class="postzegel mb-2">
                            <img src="${item.image_thumb_sm}" width="100" alt />
                        </a>
                        <div><a href="/files/${item.file_id}">${item.scan_code}</a></div>
                        <div>${createdAt}</div>
                        ${address ? '<div>' + address + '</div>' : ''}
                        <div>${item.latitude} / ${item.longitude}</div>`,
                    )

                    marker.bindPopup(popup)

                    this.markers.addLayer(marker)
                }
            })

            // only add markers if there are any
            const layersToBeAdded = this.markers.getLayers()

            if (layersToBeAdded.length) {
                this.map.addLayer(this.markers)
                this.moveToSnapId()
            }
        },
    },
}
</script>

<style lang="scss">
@import '/node_modules/leaflet/dist/leaflet.css';
@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
