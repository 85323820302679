<template>
    <div class="dark-heading">
        <Spinner v-if="loading"></Spinner>

        <div v-else-if="!issue" class="text-center">
            <img height="250" src="/images/snappy/uhooh2.png" alt />
            <div class="text-gray">{{ $t('main.not_found') }}</div>
        </div>

        <div v-else class="issues-show" :class="{ isClosed }">
            <div class="context-bar">
                <div class="context-left">
                    <router-link class="btn btn-sm s-circle mr-2" :to="{ name: 'issues' }" exact>
                        <i class="far fa-arrow-left"></i>
                    </router-link>
                    <h2 @click.prevent="editIssueTitle()" class="c-hand">
                        <span class="mr-2">{{ issue.title }}</span>
                        <i class="fa-regular fa-pencil fa-2xs"></i>
                    </h2>
                </div>

                <div class="context-right">
                    <Dropdown class="dropdown-right ml-auto">
                        <div class="menu-item">
                            <a href="#" @click.prevent="editIssueTitle()">{{ $t('issues.edit_title') }}</a>
                        </div>
                        <div class="menu-item" v-if="issue.status">
                            <a href="#" @click.prevent="closeIssue()">{{ $t('issues.close_issue') }}</a>
                        </div>
                        <div class="menu-item" v-else>
                            <a href="#" @click.prevent="openIssue()">{{ $t('issues.reopen_issue') }}</a>
                        </div>
                        <div class="menu-item text-error">
                            <a href="#" @click.prevent="deleteIssue()">{{ $t('main.delete') }}</a>
                        </div>
                    </Dropdown>
                </div>
            </div>

            <div class="box page-header mb-6">
                <table class="page-header-info">
                    <tbody>
                        <tr>
                            <th>{{ $t('issues.number') }}</th>
                            <th>{{ $t('main.created') }}</th>
                            <th>{{ $t('issues.created_by') }}</th>
                            <th>{{ $t('issues.status') }}</th>
                        </tr>
                        <tr>
                            <td>
                                <h2>#{{ issue.issue_number }}</h2>
                            </td>
                            <td>
                                <DateTime :date="issue.created_at"></DateTime>
                            </td>
                            <td>
                                <div class="flex-start">
                                    <Avatar :initials="issue.author.name"></Avatar>
                                    <div class="ml-2">{{ issue.author.name }}</div>
                                </div>
                            </td>
                            <td>
                                <button class="btn" @click="toggleStatus()">
                                    <i class="fa-solid fa-circle"
                                        :class="[issue.status ? 'text-error' : 'text-gray']"></i>
                                    <span class="ml-2">{{ $t('issues.issue_status', issue.status) }}</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="columns">
                <div class="column col-8 col-lg-12">
                    <IssueDescription :issue="issue" @update="fetchIssue()" class="mb-6"></IssueDescription>

                    <div class="issue-timeline box mb-4" :class="{ showOnlyComments }">
                        <div class="flex-space mb-6">
                            <h5 class="text-bold m-0 mr-2">{{ $t('issues.activity') }}</h5>
                            <button class="btn btn-link text-gray  ml-2" @click="toggleCommentsOnly()">
                                <i class="fa-solid fa-fw" :class="[showOnlyComments ? 'fa-eye' : 'fa-eye-slash']"></i>
                                <span class="ml-2">{{ $t('issues.show_comments_only') }}</span>
                            </button>
                        </div>
                        <IssueTimeline :timeline="issue.timeline" @update="fetchIssue()"></IssueTimeline>
                    </div>

                    <TextEditor v-model="comment" :placeholder="$t('issues.write_a_comment')" class="mt-4"
                        :buttonText="$t('issues.post_comment')" @save="createComment()">
                    </TextEditor>
                </div>
                <div class="column col-4 col-lg-12">
                    <IssueAssignees v-model="issue.assignments" @update="fetchIssue()"></IssueAssignees>
                    <IssueAssociations :associations="issue.associations" @update="fetchIssue()"></IssueAssociations>
                    <IssueAttachments></IssueAttachments>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '~/components/Avatar.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'
import IssueTimeline from '~/components/issues/IssueTimeline.vue'
import IssueAssignees from '~/components/issues/IssueAssignees.vue'
import IssueAssociations from '~/components/issues/IssueAssociations.vue'
import IssueAttachments from '~/components/issues/IssueAttachments.vue'
import IssueDescription from '~/components/issues/IssueDescription.vue'
import transformTimeline from '~/utils/transformIssueTimeline.js'

export default {
    components: {
        Avatar,
        TextEditor,
        IssueTimeline,
        IssueAssignees,
        IssueAssociations,
        IssueAttachments,
        IssueDescription,
    },

    data() {
        return {
            issue: null,
            timer: null,
            loading: true,

            comment: '',
            issue_number: this.$route.params.issue_number,
            showOnlyComments: this.$store.state.issueTimelineShowOnlyComments,
        }
    },

    computed: {
        isClosed() {
            return this.issue.status === 0
        },
    },

    async mounted() {
        await this.fetchIssue()

        this.loading = false
        this.timer = setInterval(() => {
            try { this.fetchIssue() } catch { }
        }, 10000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },

    methods: {
        async fetchIssue() {
            const { data: issue } = await this.$axios.get(`issues/${this.issue_number}`)

            // Prevent overwriting the entire issue object 
            // to maintain the current state and avoid state issues
            if (!this.issue) this.issue = issue

            // Synchronize the timeline
            this.issue.timeline = transformTimeline(issue.timeline)
        },

        toggleCommentsOnly() {
            this.showOnlyComments = !this.showOnlyComments
            this.$store.commit('UPDATE_ISSUE_TIMELINE_SHOW_ONLY_COMMENTS', this.showOnlyComments)
        },

        editIssueTitle() {
            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#0092c8',
                inputValue: this.issue.title,
                title: this.$t('issues.edit_title'),
                confirmButtonText: this.$t('main.update'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: async value => {
                    try {
                        await this.$axios.patch(`issues/${this.issue_number}`, { title: value })
                        this.issue.title = value
                        this.fetchIssue()
                    } catch ({ response }) {
                        this.$swal.showValidationMessage(response.data?.message)
                    }
                },
            })
        },

        deleteIssue() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                await this.$axios.delete(`issues/${this.issue_number}`)
                this.$router.push({ name: 'issues' })
                window.toast(this.$root.$t('i.delete', { i: this.issue.title }))
            })
        },

        async createComment() {
            if (this.isClosed) return

            await this.$axios.post(`issues/${this.issue_number}/comments`, { description: this.comment })
            await this.fetchIssue()
            this.comment = ''
        },

        async toggleStatus() {
            await this.issue.status ? this.closeIssue() : this.openIssue()
        },

        async closeIssue() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('issues.closing_the_issue_will_prevent_changes'),
                confirmButtonText: this.$t('issues.close_issue'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                this.issue.status = 0
                await this.$axios.patch(`issues/${this.issue_number}/close`)
                this.fetchIssue()
            })
        },

        async openIssue() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('issues.reopen_issue'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                this.issue.status = 1
                await this.$axios.patch(`issues/${this.issue_number}/open`)
                this.fetchIssue()
            })
        },
    },
}
</script>