<template>
    <Modal v-if="activeKiosk" @close="$router.push({ name: 'kiosk' })">
        <form @submit.prevent="submit()">
            <Errors :errors="errors" />

            <div v-for="step in activeKiosk.steps" :key="step.id" class="form-group-holder">
                <div v-for="(answer, index) in step.answers" :key="index" class="form-group"
                    :class="{ 'has-error': answer.invalid }">
                    <label class="form-label" :for="'step-' + step.id">
                        {{ step.question_translations[language] || step.question }}
                        <span v-if="step.required" class="required">*</span>
                    </label>

                    <div v-if="answer.invalid" class="form-input-hint">{{ $t(answer.invalidReason) }}</div>
                    <div class="input-group">
                        <KioskStepWithMask :step="step" :answer="answer" @change="validateAnswer(step, answer)">
                        </KioskStepWithMask>
                        <button type="button" class="btn btn-clear" @click="removeAnswerFromStep(step, index)"></button>
                    </div>
                </div>

                <div class="form-group">
                    <button class="btn" type="button" v-if="step.delimiter" @click="addAnswerToStep(step)">
                        <i class="fas fa-plus mr-2"></i>
                        <span>{{ $t('forms.add_field') }}</span>
                    </button>
                </div>
            </div>

            <div class="form-group-holder flex-space">
                <button class="btn btn-primary btn-lg" type="submit" :class="{ loading }">
                    {{ $t('main.confirm') }}
                </button>

                <LanguageDropdown class="dropdown-right"></LanguageDropdown>
            </div>
        </form>
    </Modal>
</template>

<script>
import { mapState } from 'vuex'
import KioskStepWithMask from './KioskStepWithMask.vue'

export default {
    props: ['kiosks'],

    computed: mapState(['language']),

    components: { KioskStepWithMask },

    data() {
        return {
            errors: null,
            loading: false,
            activeKiosk: null,
        }
    },

    created() {
        const kiosk = this.kiosks.find(k => k.id == this.$route.params.id)

        kiosk.steps.forEach(step => {
            step.answers = [{ value: '', invalid: false, invalidReason: '' }]
            step.question_translations = step.question_translations || {}
        })

        this.activeKiosk = kiosk
    },

    methods: {
        async submit() {
            const valid = this.validateSteps(this.activeKiosk.steps)

            if (!valid) return

            this.activeKiosk.steps.map(step => {
                // transform the answer array into a single answer
                // if delimiter the answers will be joined together as one string
                step.answer = step.answers.map(a => a.value).join(step.delimiter)

                return step
            })

            this.loading = true

            try {
                await this.$axios.post('kiosk_submit', this.activeKiosk)

                window.toast(this.$root.$t('kiosk.submitted'))

                this.$router.push({ name: 'kiosk' })
            } catch ({ response }) {
                this.errors = response
            }

            this.loading = false
        },

        validateSteps(steps) {
            let valid = true

            steps.forEach(step => {
                step.answers.forEach(answer => {
                    this.validateAnswer(step, answer)

                    if (answer.invalid) {
                        valid = false
                    }
                })
            })

            return valid
        },

        validateAnswer(step, answer) {
            answer.invalid = false
            answer.invalidReason = ''

            if (step.required && !answer.value) {
                answer.invalid = true
                answer.invalidReason = 'kiosk.answer_required'
            }

            if (step.mask && answer.value) {
                const maskLength = step.mask.replace(/!/g, '').length

                if (maskLength !== answer.value.length) {
                    answer.invalid = true
                    answer.invalidReason = 'kiosk.answer_format_invalid'
                }
            }
        },

        addAnswerToStep(step) {
            if (step.answers.length === 10) return alert('Max 10 entries')

            const stepValid = this.validateSteps([step])

            if (stepValid) {
                step.answers.push({
                    value: '',
                    invalid: false,
                    invalidReason: '',
                })
            }
        },

        removeAnswerFromStep(step, index) {
            step.answers.splice(index, 1)
        },
    },
}
</script>
