<template>
    <Modal :title="$t('fields.geofence_options')">
        <div id="map" class="box"></div>

        <template #footer>
            <button class="btn btn-primary" @click="save()">{{ $t('main.save') }}</button>
            <button class="btn ml-2" @click="$emit('close')">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import * as L from 'leaflet'
import 'leaflet-draw'

export default {
    props: ['data'],

    data: () => ({
        items: [],

        editableLayers: null,
    }),

    mounted() {
        const layer = L.tileLayer(window.config.openStreetMap.skin, {
            attribution: window.config.openStreetMap.copyright,
            maxZoom: 19,
        })

        const map = L.map('map', {
            zoom: 2,
            minZoom: 2,
            layers: [layer],
            center: [51.505, -0.09],
        })

        this.editableLayers = new L.FeatureGroup()

        const drawControl = new L.Control.Draw({
            draw: {
                marker: false,
                polygon: false,
                polyline: false,
                rectangle: false,
                circlemarker: false,
            },
            edit: { featureGroup: this.editableLayers },
        })

        map.addLayer(this.editableLayers)
        map.addControl(drawControl)

        map.on(L.Draw.Event.CREATED, ({ layer }) => {
            this.addLayer(layer)
            setTimeout(() => {
                layer.openPopup()
            })
        })

        this.data.forEach(item => {
            const circle = new L.Circle([item.latitude, item.longitude], {
                radius: item.radius,
            })

            this.addLayer(circle, item.name)
        })
    },

    methods: {
        addLayer(layer, name = false) {
            const input = document.createElement('input')
            input.placeholder = 'Geofence region'
            input.type = 'text'

            const popup = L.popup().setLatLng(layer.getLatLng()).setContent(input)

            layer.bindPopup(popup)
            this.editableLayers.addLayer(layer)

            if (name) {
                input.value = name
                layer.bindTooltip(name, { permanent: true, direction: 'top' })
            }

            layer.on('click', () => {
                layer.openPopup()
            })

            layer.on('popupclose', ({ popup }) => {
                const tooltip = layer.getTooltip()
                const inputValue = popup.getContent().value

                if (tooltip) {
                    tooltip.setContent(inputValue)
                } else {
                    layer.bindTooltip(inputValue, {
                        permanent: true,
                        direction: 'top',
                    })
                }
            })
        },

        save() {
            const data = []
            const layers = this.editableLayers.getLayers()

            layers.forEach(layer => {
                layer.closePopup()

                const latLng = layer.getLatLng()
                const tooltip = layer.getTooltip()

                if (tooltip) {
                    data.push({
                        name: tooltip.getContent(),
                        latitude: latLng.lat,
                        longitude: latLng.lng,
                        radius: layer.getRadius(),
                    })
                }
            })

            this.$emit('save', data)
        },
    },
}
</script>

<style lang="scss">
@import '/node_modules/leaflet/dist/leaflet.css';
@import '/node_modules/leaflet-draw/dist/leaflet.draw.css';
</style>
