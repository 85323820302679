<template>
    <div class="dark-heading">
        <CompanyLogo v-if="file.company?.logo" :company="file.company" class="mt-4" />

        <div v-if="fileNotFound" class="text-center">
            <img height="250" src="/images/snappy/uhooh2.png" alt />
            <div class="text-gray">{{ $t('share.expired') }}</div>
        </div>

        <FileShow v-if="file.id" :file="file" class="shared-file" backRoute="sharing">
            <template #actions>
                <button v-if="allow_zip_download" class="btn" @click="downloadZip()"
                    :class="{ loading: downloading_zip }">
                    {{ $t('files.download_images_as_zip') }}
                </button>
                <button v-if="allow_pdf_download" class="btn" @click="downloadPdf()"
                    :class="{ loading: downloading_pdf }">
                    {{ $t('files.download_images_as_pdf') }}
                </button>
            </template>
        </FileShow>

        <div v-else class="loading loading-lg" style="margin-top: 50px"></div>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'

export default {
    components: { FileShow, CompanyLogo },

    data() {
        return {
            file: {},
            fileNotFound: false,

            downloading_zip: false,
            allow_zip_download: false,

            downloading_pdf: false,
            allow_pdf_download: false,

            id: this.$route.params.id,
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            try {
                const { data: file } = await this.$axios.get(`sharing/${this.id}`)

                file.timeline = file.uploads.concat(file.form_submits)

                const options = file.share.options

                if (options) {
                    this.allow_pdf_download = options.allow_pdf_download
                    this.allow_zip_download = options.allow_guest_download

                    if ('download_pdf' in this.$route.query) {
                        this.downloadPdf()
                        this.$router.replace({ ...this.$router.currentRoute, query: {} })
                    }
                }

                this.file = file
            } catch {
                this.fileNotFound = true
            }
        },

        async downloadZip() {
            window.toast(this.$root.$t('files.download_images_as_zip'))

            this.downloading_zip = true

            const { data: zip } = await this.$axios.get(`share/${this.file.share?.token}/zip`)

            this.downloading_zip = false

            const link = document.createElement('a')

            link.href = zip

            document.body.appendChild(link)
            link.click()
        },

        async downloadPdf() {
            window.toast(this.$root.$t('files.download_images_as_pdf'))

            this.downloading_pdf = true

            const { data: pdf } = await this.$axios.get(`share/${this.file.share?.token}/pdf`)

            this.downloading_pdf = false

            const link = document.createElement('a')

            link.href = pdf
            link.target = '_blank'

            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>
