<template>
    <Modal :title="$t('main.shares')" @close="$router.push({ name: 'files.show' })">
        <template #subtitle>
            <router-link :to="{ name: 'files.share.create' }">« {{ $t('main.back') }}</router-link>
        </template>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('users.email') }}</th>
                    <th>{{ $t('main.expiry') }}</th>
                    <th></th>
                </tr>
            </thead>
            <tr v-for="share in shares">
                <td>{{ share.email }}</td>
                <td>
                    <span v-if="share.expiry_date">
                        <DateTime :date="share.expiry_date"></DateTime>
                    </span>
                    <span v-else>&infin;</span>
                </td>
                <td class="text-right">
                    <a :href="`/share/${share.token}`" target="_blank" class="btn">
                        <i class="fas fa-eye"></i>
                    </a>
                    <button class="btn ml-2" @click="deleteShare(share)">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        </table>
    </Modal>
</template>

<script>
export default {
    props: { file: Object },

    data: () => ({ shares: [] }),

    async mounted() {
        const { data: paginator } = await this.$axios.get(`files/${this.file.id}/shares`, {
            params: { limit: 200 },
        })

        this.shares = paginator.data
    },

    methods: {
        deleteShare(share) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`files/${this.file.id}/shares/${share.id}`).then(() => {
                        this.shares.splice(this.shares.indexOf(share), 1)
                        window.toast(this.$root.$t('i.delete', { i: this.$root.$t('main.share') }))
                    })
                }
            })
        },
    },
}
</script>
