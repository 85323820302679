<template>
    <div>
        <div v-if="can('Read files')" class="nav-item">
            <router-link :to="{ name: 'files' }">
                <i class="fa-regular fa-barcode fa-fw"></i>
                <span>{{ $t('main.files') }}</span>
            </router-link>
        </div>

        <div v-if="can('Read file shares')" class="nav-item">
            <router-link :to="{ name: 'sharing' }">
                <i class="fa-regular fa-share-nodes fa-fw"></i>
                <span>{{ $t('main.sharing') }}</span>
            </router-link>
        </div>

        <div v-if="can('Read files')" class="nav-item">
            <router-link :to="{ name: 'workflows' }">
                <i class="fa-regular fa-arrow-progress fa-flip-vertical fa-fw"></i>
                <span>{{ $t('main.workflows') }}</span>
            </router-link>
        </div>

        <div v-if="can('Read form submits')" class="nav-item">
            <router-link :to="{ name: 'forms' }">
                <i class="fa-regular fa-list-check fa-fw"></i>
                <span>{{ $t('main.forms') }}</span>
            </router-link>
        </div>

        <div v-if="can('Read reports') && !user.properties.use_report_in_browser" class="nav-item">
            <router-link :to="{ name: 'reports' }">
                <i class="fa-regular fa-file-invoice fa-fw"></i>
                <span>{{ $t('main.reports') }}</span>
            </router-link>
        </div>

        <div v-if="can('Read kiosk submits') && user.properties.use_kiosks" class="nav-item">
            <router-link :to="{ name: 'kiosk_submits' }">
                <i class="fa-solid fa-tv fa-fw"></i>
                <span>{{ $t('kiosk.kiosk_submits') }}</span>
            </router-link>
        </div>

        <div v-if="hasIssueManagement" class="nav-item">
            <router-link :to="{ name: 'issues' }">
                <i class="fa-regular fa-circle-dot fa-fw"></i>
                <span>{{ $t('main.issues') }}</span>
                <span class="label bg-primary text-small text-bold px-2 ml-2">beta</span>
            </router-link>
        </div>

        <div v-if="can('Use convert to trial') && user.company.status === 'free'" class="nav-item">
            <router-link class="bg-primary" :to="{ name: 'trial' }">{{ $t('trial.activate') }}</router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['user'],
    computed: mapGetters(['can', 'hasIssueManagement']),
}
</script>
