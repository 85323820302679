import { createRouter, createWebHistory } from 'vue-router'

import store from '~/store/index.js'
import routes from './routes.js'

const router = createRouter({
    routes,
    history: createWebHistory(),
    linkExactActiveClass: 'active',
})

router.afterEach((to, from) => {
    if (to.meta.layout) {
        store.dispatch('setLayout', to.meta.layout)
    }

    if (store.state.intercomSettings.user_hash && !store.state.user.impersonate_tenant_id) {
        window.Intercom && window.Intercom('update', store.state.intercomSettings)
    }

    const jwt = store.state.jwt

    if (jwt?.issued_at) {
        const now = Math.round(new Date().getTime() / 1000)

        if (now - jwt.issued_at > 3600) {
            store.dispatch('refreshJWT')
        }
    }
})

router.onError((error, to) => {
    // Do not reload in development mode
    if (import.meta.env.MODE === 'development') return

    if (
        error.message.includes('Importing a module script failed') ||
        error.message.includes('error loading dynamically imported module') ||
        error.message.includes('Failed to fetch dynamically imported module')
    ) {
        if (to?.fullPath) {
            window.location = to.fullPath
        } else {
            window.location.reload()
        }
    }
})

export default router
