<template>
    <table class="table">
        <tbody v-if="attributes">
            <tr v-if="version">
                <td class="text-gray">{{ $t('devices.attr_app_version') }}</td>
                <td>{{ version }}</td>
            </tr>
            <tr v-if="attributes.platform">
                <td class="text-gray">{{ $t('devices.attr_platform') }}</td>
                <td>
                    <span>{{ attributes.platform }}</span>
                    <i class='fab mx-1' :class="[`fa-${attributes.platform === 'ios' ? 'apple' : 'android'}`]"></i>
                    <span v-if="attributes.platform_version">({{ attributes.platform_version }})</span>
                </td>
            </tr>
            <tr v-if="attributes.model">
                <td class="text-gray">{{ $t('devices.attr_model') }}</td>
                <td>{{ attributes.model }}</td>
            </tr>
            <tr v-if="attributes.manufacturer">
                <td class="text-gray">{{ $t('devices.attr_manufacturer') }}</td>
                <td>{{ attributes.manufacturer }}</td>
            </tr>
            <tr v-if="attributes.locale">
                <td class="text-gray">{{ $t('devices.attr_locale') }}</td>
                <td>{{ attributes.locale }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        version: String,
        attributes: Object
    }
}
</script>