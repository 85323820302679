<template>
    <Modal :title="form.title" @close="$router.push({ name: 'files.show' })">
        <form v-if="form.id" @submit.prevent="submit()" ref="form">
            <template v-for="field in form.fields">
                <FormFieldsModel v-if="!field.deleted_at" v-model="field.answer" :key="field.id" :field="field"
                    :label="field.label" :required="field.required" class="form-group" bold></FormFieldsModel>
            </template>

            <input type="submit" hidden />
        </form>
        <div v-else class="loading loading-lg"></div>

        <template #footer v-if="form.id">
            <button class="btn btn-primary" :class="{ loading }" @click="$refs.form.requestSubmit()">
                {{ $t('files.submit_form') }}
            </button>
        </template>
    </Modal>
</template>

<script>
import FormFieldsModel from '~/components/files/FileFormFieldsModel.vue'

export default {
    components: { FormFieldsModel },

    data() {
        return {
            form: {},
            loading: false,
            file_id: this.$route.params.id,
            form_id: this.$route.params.form_id,
        }
    },

    async mounted() {
        const { data: form } = await this.$axios.get(`forms/${this.form_id}`)

        form.fields.forEach(field => field.answer = field.options.default || '')

        this.form = form
    },

    methods: {
        submit() {
            if (this.loading) return

            this.loading = true
            this.form.form_id = this.form_id
            this.form.file_id = this.file_id

            this.$axios.post('submits', this.form).then(() => {
                this.$router.push({ name: 'files.show', params: { id: this.file_id } })
                this.$emit('refresh')
            })
        },
    },
}
</script>
