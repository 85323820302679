<template>
    <div>
        <ContextBar :title="$t('main.dashboard')"></ContextBar>

        <Counters v-if="preferences.counters"></Counters>
        <MapsView v-if="preferences.maps" :uploads="allUploads"></MapsView>

        <div class="files-show my-4" :class="{
            'can-edit-snap': canEditSnaps,
            'can-edit-snap': canEditSnaps,
            'can-delete-snap': canDeleteSnaps,
            'can-delete-submit': canDeleteSubmits,
        }">
            <EmptyDashboard v-if="!allUploads.length && !hasMore"></EmptyDashboard>
            <Timeline v-for="uploads in pages" :uploads="uploads"></Timeline>

            <div v-if="hasMore" class="text-center" style="margin-top: 50px">
                <button @click="fetchUploads()" class="btn btn-link loading-lg" :class="{ loading }">
                    {{ $t('timeline.load_more') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import MapsView from '~/components/MapsView.vue'
import Counters from '~/components/Counters.vue'
import Timeline from '~/components/timeline/Timeline_old.vue'
import EmptyDashboard from '~/components/empty_states/EmptyDashboard.vue'

export default {
    components: { Timeline, Counters, MapsView, EmptyDashboard },

    data() {
        return {
            pages: [],

            hasMore: true,
            loading: false,

            preferences: this.$store.state.preferences,

            canEditSnaps: this.$store.getters.can('Update snaps'),
            canEditSubmits: this.$store.getters.can('Update form submits'),
            canDeleteSnaps: this.$store.getters.can('Delete snaps'),
            canDeleteSubmits: this.$store.getters.can('Delete form submits'),
        }
    },

    computed: {
        allUploads() {
            return this.pages.flat()
        }
    },

    mounted() {
        this.fetchUploads()
        window.addEventListener('scroll', this.handleScroll)
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        async fetchUploads() {
            this.loading = true

            try {
                const { data: paginator } = await this.$axios.get('uploads', { params: { page: this.pages.length + 1 } })

                this.pages.push(paginator.data)
                this.hasMore = paginator.next_page_url
            } catch { }

            this.loading = false
        },

        handleScroll() {
            if (this.loading || !this.hasMore) return
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                this.fetchUploads()
            }
        },
    },
}
</script>
