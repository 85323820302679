<template>
    <div v-if="issues.length" class="mt-2">
        <router-link v-for="issue in issues" :to="{ name: 'issues.show', params: { issue_number: issue.issue_number } }"
            class="d-inline-block box p-2 mr-2 mt-2">
            <div class="flex-start mx-2">
                <div class="text-gray mr-2">#{{ issue.issue_number }}</div>
                <div class="text-bold">{{ issue.title }}</div>
                <div class="chip chip-lg ml-2" :class="[issue.status ? 'chip-error' : 'text-gray']">
                    <i class="fa-solid fa-circle"></i>
                    <span class="ml-2">{{ $t('issues.issue_status', issue.status) }}</span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        issues: {
            type: Array,
            default: () => [],
        }
    }
}
</script>