<template>
    <Dropdown class="file-search-dropdown dropdown-right dropdown-big" @open="focusSearch()">
        <template #toggle>
            <slot>
                <i class="fa-regular fa-magnifying-glass fa-fw"></i>
            </slot>
        </template>
        <div class="nav">
            <DebounceInput v-model="search" clearable searchIcon @keyup.enter="$refs.results?.click()"
                :placeholder="$t('files.search_on_reference')" ref="search" />

            <div v-if="!onSelectFile && files.length" class="search-header flex-space">
                <div class="text-bold h5">{{ $t('table.show_results', files.length) }}</div>
                <a href="#" ref="results" @click.prevent="showAllResults()">{{ $t('table.show_all_results') }}</a>
            </div>

            <div class="search-result">
                <div v-if="files.length" class="result-list">
                    <a v-if="onSelectFile" href="#" v-for="(file, index) in files"
                        :class="{ active: selectedFiles.includes(file.id) }" :tabindex="index + 10"
                        class="result-list-item flex-space c-hand" @click.prevent="$emit('selectFile', file)">
                        <div>
                            <div class="text-clamped">{{ file.scan_code }}</div>
                            <DateTime :date="file.created_at" ago class="text-gray"></DateTime>
                        </div>
                        <div class="result-list-actions">
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                    </a>

                    <router-link v-else v-for="(file, index) in files" :tabindex="index + 10"
                        class="result-list-item flex-space" :to="{ name: 'files.show', params: { id: file.id } }">
                        <div>
                            <div class="text-clamped">{{ file.scan_code }}</div>
                            <DateTime :date="file.created_at" ago class="text-gray"></DateTime>
                        </div>

                        <div class="result-list-actions">
                            <a v-if="file.closed_at" href="#" tabindex="-1">
                                <i class="fa-solid fa-lock fa-fw"></i>
                            </a>
                            <router-link :to="{ name: 'files.share.create', params: { id: file.id } }">
                                <i class="fa-regular fa-share-nodes fa-fw"></i>
                            </router-link>
                        </div>
                    </router-link>
                </div>

                <div v-else-if="!search || loading" class="text-center">
                    <figure class="avatar avatar-lg bg-linen text-dark mb-4" :class="{ loading }">
                        <i class="fa-regular fa-magnifying-glass fa-xs"></i>
                    </figure>
                    <h5 class="text-bold mb-1">{{ $t('files.search_for_files') }}</h5>
                    <p class="text-medium text-gray">{{ $t('files.begin_typing_to_search') }}</p>
                </div>

                <div v-else class="text-center">
                    <figure class="avatar avatar-lg bg-linen text-dark mb-4">
                        <i class="fa-solid fa-box-open fa-xs"></i>
                    </figure>
                    <h5 class="text-bold mb-1">{{ $t('files.no_files_found') }}</h5>
                    <p class="text-medium text-gray">{{ $t('files.try_a_different_reference') }}</p>
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>
export default {
    props: {
        selectedFiles: Array,
        onSelectFile: Function,
    },

    data() {
        return {
            files: [],
            search: null,
            loading: false,
        }
    },

    watch: {
        search(search) {
            if (search) return this.fetchData()

            this.files = []
        }
    },

    methods: {
        async fetchData() {
            setTimeout(() => { this.loading = true })

            try {
                const { data } = await this.$axios.get('search', { params: { query: this.search } })
                this.files = data
            } catch {
                this.files = []
            }

            this.loading = false
        },

        showAllResults() {
            if (this.onSelectFile) return

            this.$router.push({ name: 'files', query: { search: this.search, rd: false } })
        },

        focusSearch() {
            setTimeout(() => this.$refs.search.clear())
        },
    },
}
</script>
