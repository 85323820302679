<template>
    <div class="tenant-impersonation-indicator flex-space text-light">
        <div>
            <i class="fa-solid fa-eye fa-fw mr-2"></i>
            <span>Impersonating {{ company.company }} ({{ company.id }})</span>
        </div>
        <button class="btn btn-clear" @click="stopImpersonating()"></button>
    </div>
</template>

<script>
export default {
    props: ['company'],

    methods: {
        stopImpersonating() {
            this.$axios.post('admin/impersonate').then(() => window.location.reload())
        },
    },
}
</script>
