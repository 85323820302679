<template>
    <div>
        <ContextBar :title="$t('main.files')" :buttonText="$t('context.create_files')" buttonAbility="Create files"
            buttonRoute="files.create">
            <DebounceInput v-model="search" :placeholder="$t('files.search_on_reference')" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="files" :columns="columns" :parameters="parameters" :ignoreUrlParameters="['fields']"
            :selectable="true" :key="refresh" hasFilters>
            <template #with_selected_ids="{ ids }">
                <div class="menu-item">
                    <router-link :to="{ name: 'files.multiple.add_field', query: { files: ids } }">
                        {{ $t('files.add_field') }}
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link :to="{ name: 'files.multiple.create_report', query: { files: ids } }">
                        {{ $t('files.create_report') }}
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link :to="{ name: 'files.multiple.download_zip', query: { files: ids } }">
                        {{ $t('main.download') }}
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link :to="{ name: 'files.multiple.send_images', query: { files: ids } }">
                        {{ $t('files.send_by_email') }}
                    </router-link>
                </div>
                <div v-if="canClose" class="menu-item">
                    <a href="#" @click.prevent="closeFiles(ids)">{{ $t('files.close_files', ids.length) }}</a>
                </div>
                <div v-if="canDelete" class="menu-item text-error">
                    <a href="#" @click.prevent="deleteFiles(ids)">{{ $t('files.delete_files', ids.length) }}</a>
                </div>
            </template>

            <template #context-right>
                <label class="form-checkbox" v-if="$store.state.user.properties.use_close_files">
                    <input type="checkbox" v-model="is_open" @change="updateFileIsOpenCheckbox()" />
                    <i class="form-icon"></i>
                    <span>{{ $t('files.open') }}</span>
                </label>

                <label class="form-checkbox">
                    <input type="checkbox" v-model="contains_damage" />
                    <i class="form-icon"></i>
                    <span>{{ $t('files.contains_damage') }}</span>
                </label>

                <DatePicker v-model="date_range" :placeholder="$t('files.created_between')" range multi-calendars>
                </DatePicker>

                <MultiSelect v-model="device_id" label="nickname" :options="devices"
                    :reduce="device => String(device.id)" :placeholder="$t('files.search_on_device')">
                </MultiSelect>

                <FieldSearchDropdown :searchFields="fields"></FieldSearchDropdown>
            </template>

            <template #column(thumb)="{ item }">
                <div v-if="item.recent_upload" class="postzegel">
                    <img :src="item.recent_upload.image_thumb_sm" alt width="100" />
                </div>
            </template>
            <template #column(scan_code)="{ item }">
                <FileReference :id="item.id" :reference="item.scan_code" :closed_at="item.closed_at"></FileReference>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(uploads_count)="{ value }">
                <div class="chip chip-lg">
                    <i class="fa-regular fa-circle-arrow-up"></i>
                    <span class="ml-2">{{ value }}</span>
                </div>
            </template>
            <template #column(damages_count)="{ value }">
                <div class="chip chip-lg" :class="{ 'bg-error': value }">
                    <i class="fa-regular fa-circle-exclamation"></i>
                    <span class="ml-2">{{ value }}</span>
                </div>
            </template>
            <template #column(fields)="{ item }">
                <Fields :fields="item.fields" indexPage></Fields>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import DataTable from '~/components/DataTable.vue'
import Fields from '~/components/files/FileFields.vue'
import FileReference from '~/components/FileReference.vue'
import FieldSearchDropdown from '~/components/FieldSearchDropdown.vue'

export default {
    components: {
        Fields,
        DataTable,
        FileReference,
        FieldSearchDropdown,
    },

    data() {
        return {
            refresh: 0,
            devices: [],
            rd: this.$route.query.rd,

            search: this.$route.query.search,
            is_open: this.$route.query.is_open || this.$store.state.filesTableIsOpenCheckbox,
            device_id: this.$route.query.device_id,
            date_range: this.$route.query.date_range,
            contains_damage: this.$route.query.contains_damage,
            fields: this.$store.state.searchFields,

            columns: [
                { name: 'thumb' },
                { name: 'scan_code', th: 'files.reference', sortable: true, class: 'mw-250' },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'uploads_count', th: 'files.uploads', sortable: true },
                { name: 'damages_count', th: 'files.damages', sortable: true },
                { name: 'fields', th: 'main.fields', class: 'mw-250' },
            ],

            canClose: this.$store.getters.can('Close files'),
            canDelete: this.$store.getters.can('Delete files'),
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                is_open: this.is_open || undefined,
                contains_damage: this.contains_damage || undefined,
                date_range: this.date_range || undefined,
                device_id: this.device_id || undefined,
                fields: this.fields.filter(field => field.enabled) || undefined,
                rd: this.rd || false, // TODO: remove => V1 search backwards compatibility
            }
        },
    },

    watch: {
        // So that we can set the search from Navbar search dropdown
        '$route.query.search'(search) {
            this.search = search || undefined
        },
    },

    // V1 search backwards compatibility
    // TODO: Remove once all clients have moved to /redirect endpoint
    async mounted() {
        const search = this.$route.query.search || this.$route.query.scan_code
        if (search && this.rd === undefined) {
            this.$router.replace({ name: 'redirect', params: { endpoint: 'files' }, query: { search } })
        }

        await this.fetchDevices()
    },

    methods: {
        async fetchDevices() {
            const { data: paginator } = await this.$axios.get('devices', {
                params: { sort: 'nickname', by: 'asc', limit: 200 },
            })

            this.devices = paginator.data
        },

        closeFiles(ids) {
            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: this.$t('files.close_files', ids.length),
                confirmButtonText: this.$t('files.close_files', ids.length),
                inputPlaceholder: this.$t('files.type_the_number_of_files_to_close'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: value => {
                    if (value == ids.length) return this.$axios.patch(`files/${ids.join(',')}/close`)

                    this.$swal.showValidationMessage(this.$t('files.type_the_number_of_files_to_close'))
                },
            }).then(({ value }) => value && this.refresh++)
        },

        deleteFiles(ids) {
            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: this.$t('files.delete_files', ids.length),
                confirmButtonText: this.$t('files.delete_files', ids.length),
                inputPlaceholder: this.$t('files.type_the_number_of_files_to_delete'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: value => {
                    if (value == ids.length) return this.$axios.delete(`files/${ids.join(',')}`)

                    this.$swal.showValidationMessage(this.$t('files.type_the_number_of_files_to_delete'))
                },
            }).then(({ value }) => value && this.refresh++)
        },

        updateFileIsOpenCheckbox() {
            this.$store.commit('UPDATE_FILES_TABLE_IS_OPEN_CHECKBOX', this.is_open)
        },
    },
}
</script>
