import * as Sentry from '@sentry/vue'

const sentryInit = (dsn, app, router) => {
    if (!dsn) return

    const environment = import.meta.env.VITE_SENTRY_ENV

    Sentry.init({
        app,
        dsn,
        environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: true,
                maskAllInputs: true,
                blockAllMedia: true,
            }),
        ],

        tracesSampleRate: 0.04,
        tracePropagationTargets: ['localhost', /^\//],
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
            'canceled',
            'Network Error',
            'Request aborted',
            'Importing a module script failed',
            'AbortError: The operation was aborted',
            'error loading dynamically imported module',
            'Failed to fetch dynamically imported module',
            'Object captured as promise rejection with keys',
            'Non-Error promise rejection captured with value',
            '\'text/html\' is not a valid JavaScript MIME type',
        ],
    })
}

export default sentryInit