<template>
    <div class="platform">
        <NavBar :user="user"></NavBar>

        <div class="platform-wrapper">
            <TenantImpersonationIndicator v-if="user.impersonate_tenant_id" :company="user.company" />
            <TrialExpirationIndicator v-if="user.company.trial_expires_at" :expiresAt="user.company.trial_expires_at" />

            <div class="content">
                <router-view :key="refresh"></router-view>
            </div>
        </div>

        <TenantSoftBlockedModal v-if="user.company.soft_blocked === true" />
        <NotActivatedModal v-else-if="user.activated === false" @close="user.activated = true" />
        <PasswordChangeModal v-else-if="user.needs_password_change === true" />
        <OnboardingTour v-else-if="tour.in_progress" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'

import NavBar from './navbar/NavBar.vue'
import NotActivatedModal from './NotActivatedModal.vue'
import PasswordChangeModal from './PasswordChangeModal.vue'
import TenantSoftBlockedModal from './TenantSoftBlockedModal.vue'
import TrialExpirationIndicator from './TrialExpirationIndicator.vue'
import TenantImpersonationIndicator from './TenantImpersonationIndicator.vue'
const OnboardingTour = defineAsyncComponent(() => import('./onboarding/tour/OnboardingTour.vue'))

export default {
    components: {
        NavBar,
        OnboardingTour,
        NotActivatedModal,
        PasswordChangeModal,
        TenantSoftBlockedModal,
        TrialExpirationIndicator,
        TenantImpersonationIndicator,
    },

    data: () => ({ refresh: 0 }),

    computed: mapState(['user', 'tour']),

    async mounted() {
        await this.$store.dispatch('initColorScheme')
        await this.$store.dispatch('fetchUser')

        this.$gtag.set('user_properties', { user_uuid: this.user.id, tenant_id: this.user.tenant_id })
    },

    watch: {
        $route(to, from) {
            // when url is /files?page=2 and the user clicks the /files menu item
            // the normal behaviour is to do nothing, but this logic forces a refresh
            if (to.name === from.name &&
                Object.keys(to.query).length === 0 &&
                Object.keys(from.query).length > 0) {
                this.refresh++
            }
        },
    },
}
</script>
