<template>
    <div class="my-4">
        <h4>{{ $t('trial.activate_your_free_trial') }}</h4>
        <p>{{ $t('trial.for_the_next_14_days') }}</p>
        <ul>
            <li>{{ $t('trial.create_devices') }}</li>
            <li>{{ $t('trial.upload_unlimited_snaps_and_forms') }}</li>
        </ul>
        <p>{{ $t('trial.trial_will_end_on', { date }) }}</p>
        <SaveButton @click="activate()">{{ $t('trial.activate') }}</SaveButton>
    </div>
</template>

<script>
export default {
    data: () => ({ date: dayjs().add(2, 'week').format('LL') }),

    methods: {
        async activate() {
            try {
                await this.$axios.post('activate_trial')

                this.$store.dispatch('startSession', { ...this.$store.state.jwt, status: 'trial' })

                await this.$store.dispatch('fetchUser')
                this.$router.push({ name: 'dashboard' })

                window.toast(this.$root.$t('trial.trial_has_been_activated'))
            } catch {
                window.toast(this.$root.$t('main.oops'), 'error')
            }
        },
    },
}
</script>
