<template>
    <div class="shared-file">
        <div class="navbar-background">
            <div class="navbar-wrapper">
                <header class="navbar">
                    <section class="navbar-section">
                        <CompanyLogo :company="file.company" />
                    </section>
                    <section class="navbar-section">
                        <LanguageDropdown class="dropdown-right"></LanguageDropdown>
                    </section>
                </header>
            </div>
        </div>

        <div class="platform-wrapper">
            <div class="content">
                <div v-if="file_not_found" class="text-center">
                    <img height="250" src="/images/snappy/uhooh2.png" alt />
                    <div class="text-gray">{{ $t('share.expired') }}</div>
                </div>

                <FileShow v-else-if="file.id" :file="file" :class="{ 'hide-places-in-share': hide_places }">
                    <template #actions>
                        <button v-if="allow_zip_download" class="btn" @click="downloadZip()"
                            :class="{ loading: downloading_zip }">
                            {{ $t('files.download_images_as_zip') }}
                        </button>

                        <button v-if="allow_pdf_download" class="btn ml-2" @click="downloadPdf()"
                            :class="{ loading: downloading_pdf }">
                            {{ $t('files.download_images_as_pdf') }}
                        </button>
                    </template>
                </FileShow>

                <div v-else class="loading loading-lg" style="margin-top: 50px"></div>
            </div>
        </div>

        <LoginOrRegisterModal v-if="!$store.state.loggedIn"></LoginOrRegisterModal>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'
import LoginOrRegisterModal from './shared_login_or_register_modal.vue'

export default {
    components: { FileShow, CompanyLogo, LoginOrRegisterModal },

    data() {
        return {
            file: {},
            file_not_found: false,
            token: this.$route.params.token,

            hide_places: false,
            downloading_zip: false,
            downloading_pdf: false,
            allow_zip_download: false,
            allow_pdf_download: false,
        }
    },

    async mounted() {
        try {
            const { data: file } = await this.$axios.get(`share/${this.token}`)
            const { options, hide_places } = file.share

            file.timeline = file.uploads.concat(file.form_submits)

            this.file = file
            this.hide_places = hide_places

            if (options) {
                this.allow_pdf_download = options.allow_pdf_download
                this.allow_zip_download = options.allow_guest_download

                if ('download_pdf' in this.$route.query) {
                    this.downloadPdf()
                    this.$router.replace({ ...this.$router.currentRoute, query: {} })
                }
            }
        } catch {
            this.file_not_found = true
        }
    },

    methods: {
        async downloadZip() {
            window.toast(this.$root.$t('files.download_images_as_zip'))

            this.downloading_zip = true

            const { data } = await this.$axios.get(`share/${this.token}/zip`)

            this.downloading_zip = false

            const link = document.createElement('a')

            link.href = data

            document.body.appendChild(link)
            link.click()
        },

        async downloadPdf() {
            window.toast(this.$root.$t('files.download_images_as_pdf'))

            if (this.file.company.use_report_in_browser) {
                return window.open(`/share/${this.token}/print`, '_blank')
            }

            this.downloading_pdf = true

            const { data } = await this.$axios.get(`share/${this.token}/pdf`)

            this.downloading_pdf = false

            const link = document.createElement('a')

            link.href = data
            link.target = '_blank'

            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>
