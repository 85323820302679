const ENV_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const ENV_INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID
const ENV_GOOGLE_ANALYTICS_KEY = import.meta.env.VITE_GOOGLE_ANALYTICS_KEY
const ENV_HOTJAR_SITE_ID = parseInt(import.meta.env.VITE_HOTJAR_SITE_ID)
const ENV_HOTJAR_IS_PRODUCTION = import.meta.env.VITE_HOTJAR_IS_PRODUCTION === 'true'

import { createApp } from 'vue'

// Styling
import './scss/style.scss'

// App
import App from './App.vue'
import config from './config.js'
import store from './store/index.js'
import router from './router/index.js'
import sentryInit from './sentry.js'
import intercomInit from './intercom.js'
import axiosInstance from './plugins/axios.js'
import i18n, { setI18nLanguage } from './plugins/i18n.js'

// Plugins
import VueGtag from 'vue-gtag'
import VueHotjar from 'vue-hotjar-next'
import VueLazyLoad from 'vue3-lazyload'
import VueSweetalert2 from 'vue-sweetalert2'
import Toaster from '@meforma/vue-toaster'

// Components
import Modal from './components/Modal.vue'
import Errors from './composition/Errors.vue'
import Spinner from './components/Spinner.vue'
import DateTime from './components/DateTime.vue'
import Dropdown from './composition/Dropdown.vue'
import ContextBar from './components/ContextBar.vue'
import DatePicker from './components/htmlform/DatePicker.vue'
import SaveButton from './components/htmlform/SaveButton.vue'
import MultiSelect from './components/htmlform/MultiSelect.vue'
import DebounceInput from './components/htmlform/DebounceInput.vue'
import LanguageDropdown from './components/htmlform/LanguageDropdown.vue'
import WatchForUnsavedChanges from './composition/WatchForUnsavedChanges.vue'

// Directives
import ClickOutside from './directives/onClickOutside.js'

window.config = config

const app = createApp(App)

sentryInit(ENV_SENTRY_DSN, app, router)
setTimeout(() => { intercomInit(ENV_INTERCOM_APP_ID) }, 3000)

app.use(i18n)
app.use(store)
app.use(router)

app.use(VueSweetalert2)
app.use(Toaster, { position: 'top', duration: 3000 })
app.use(VueGtag, { config: { id: ENV_GOOGLE_ANALYTICS_KEY } })
app.use(VueHotjar, { id: ENV_HOTJAR_SITE_ID, isProduction: ENV_HOTJAR_IS_PRODUCTION })
app.use(VueLazyLoad, { log: false, observerOptions: { rootMargin: '1000px 0px', threshold: 0.1 } })

app.component('Modal', Modal)
app.component('Errors', Errors)
app.component('Spinner', Spinner)
app.component('DateTime', DateTime)
app.component('Dropdown', Dropdown)
app.component('ContextBar', ContextBar)
app.component('DatePicker', DatePicker)
app.component('SaveButton', SaveButton)
app.component('MultiSelect', MultiSelect)
app.component('DebounceInput', DebounceInput)
app.component('LanguageDropdown', LanguageDropdown)
app.component('WatchForUnsavedChanges', WatchForUnsavedChanges)

app.directive('ClickOutside', ClickOutside)

app.config.globalProperties.$axios = axiosInstance

window.endTour = () => store.dispatch('endTour')
window.startTour = () => store.dispatch('startTour')
window.toast = (text, type = 'success', duration = 3000) => {
    app.config.globalProperties.$toast.show(text, { type, duration })
}

window.$netlifyContext = {
    buildId: import.meta.env.VITE_NETLIFY_BUILD_ID || 'not-set',
    deployId: import.meta.env.VITE_NETLIFY_DEPLOY_ID || 'not-set',
    context: import.meta.env.VITE_NETLIFY_CONTEXT || 'not-set',
    branch: import.meta.env.VITE_NETLIFY_BRANCH || 'not-set',
    commit: import.meta.env.VITE_NETLIFY_COMMIT || 'not-set',
    netlify_url: import.meta.env.VITE_NETLIFY_URL || 'not-set',
    netlify_deploy_url: import.meta.env.VITE_NETLIFY_DEPLOY_URL || 'not-set',
    netlify_toml_environment: import.meta.env.VITE_ENVIRONMENT || 'not-set'
}

const mountApp = async () => {
    try {
        // Load language before mounting app
        // To avoid seeing english a split second
        await setI18nLanguage(store.state.language)
    } catch { }

    app.mount('#app')
}

mountApp()