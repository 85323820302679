<template>
    <Modal :title="$t('files.select_a_form_to_submit')" @close="$router.push({ name: 'files.show' })">
        <select @change="selectForm($event.target.value)" class="form-select mb-2">
            <option disabled hidden selected>{{ $t('workflows.choose_form') }}</option>
            <option v-for="form in forms" :key="form.id" :value="form.id">{{ form.title }}</option>
        </select>
    </Modal>
</template>

<script>
export default {
    data: () => ({ forms: [] }),

    async mounted() {
        const { data: paginator } = await this.$axios.get('forms', {
            params: { limit: 200, sort: 'title', by: 'asc' },
        })

        const forms = paginator.data
        const forms_count = forms.length

        if (!forms_count) {
            window.toast(this.$root.$t('forms.no_forms_found'), 'error')

            return this.$router.push({ name: 'files.show' })
        }

        if (forms_count === 1) {
            return this.selectForm(forms[0].id)
        }

        this.forms = forms
    },

    methods: {
        selectForm(form_id) {
            this.$router.push({ name: 'files.submit_form.create', params: { form_id } })
        },
    },
}
</script>
