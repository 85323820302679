<template>
    <WatchForUnsavedChanges ref="watch" v-model="issue" v-slot="{ hasChanges }">
        <Modal :title="title || $t('context.create_issues')" @close="close()" class="issues-create">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group" :class="{ 'has-error': v$.issue.title.$error }">
                <label class="form-label" for="title">{{ $t('issues.short_description') }}</label>
                <input v-model="issue.title" id="title" class="form-input"
                    :placeholder="$t('issues.short_description')" />
            </div>

            <div class="form-group" :class="{ 'has-error': v$.issue.description.$error }">
                <label class="form-label" for="description">{{ $t('issues.long_description') }}</label>
                <TextEditor v-model="issue.description" :placeholder="$t('issues.long_description')"></TextEditor>
            </div>

            <div class="form-group">
                <label class="form-label" for="assignees">{{ $t('issues.assignees') }}</label>
                <MultiSelect v-model="issue.assignees_ids" label="name" inputId="assignees" :options="users"
                    :reduce="u => u.id" multiple avatar>
                </MultiSelect>
            </div>

            <div v-if="!associations" class="form-group">
                <label class="form-label" for="associations">{{ $t('issues.linked_files') }}</label>
                <MultiSelect v-model="issue.associations" label="scan_code" inputId="associations" :options="files"
                    :filterable="false" @search="fetchFilesBySearch" multiple>
                </MultiSelect>
            </div>

            <template #footer>
                <SaveButton @click="save()" :disabled="!hasChanges">{{ $t('issues.save_issue') }}</SaveButton>
            </template>
        </Modal>
    </WatchForUnsavedChanges>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TextEditor from '~/components/htmlform/TextEditor.vue'

const descriptionNotEmpty = v => v && v !== '<p><br></p>'

export default {
    props: {
        title: String,
        onClose: Function,
        associations: Array
    },

    emits: ['refresh'],

    components: { TextEditor },

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            issue: {
                title: '',
                status: '1',
                description: '<p><br></p>',
                associations: this.associations || [],
                assignees_ids: [this.$store.state.user.id],
            },
            files: [],
            errors: null,
            users: [this.$store.state.user],
        }
    },

    validations() {
        return {
            issue: {
                title: { required },
                description: { descriptionNotEmpty },
            },
        }
    },

    async mounted() {
        this.fetchFilesBySearch()

        const { data: paginator } = await this.$axios.get('users', {
            params: { limit: 200, sort: 'name', by: 'asc' },
        })

        this.users = paginator.data
        this.$refs.watch?.init()
    },

    methods: {
        close() {
            if (this.onClose) return this.onClose()

            this.$router.push({ name: 'issues' })
        },

        async save() {
            if (!await this.v$.$validate()) return

            const associations = this.issue.associations.map(a => ({ id: a.id, type: 'File' }))

            try {
                const { data } = await this.$axios.post('issues', { ...this.issue, associations })

                this.$router.push({ name: 'issues.show', params: { issue_number: data.issue_number } })
                this.$refs.watch?.init()

                window.toast(this.$root.$t('i.create', { i: 'Issue' }))
            } catch ({ response }) {
                this.errors = response
            }
        },

        async fetchFilesBySearch(query) {
            const { data } = await this.$axios.get('search', { params: { query } })

            this.files = data
        }
    }
}
</script>