<template>
    <Modal :title="$t('files.upload')" @close="$router.push({ name: 'files.show' })">
        <div class="form-group">
            <FilesDropper class="files-dropper" @onDrop="addFiles" @click="$refs.file.click()">
                <input type="file" ref="file" :accept="accept" multiple @change="onInputChange" class="d-none" />
                <div class="files-dropper-text">
                    <h5>{{ $t('upload.drag_and_drop_files_here') }}</h5>
                    <p>{{ $t('upload.drag_or_click_to_browse_files') }}</p>
                </div>
                <div class="upload-list">
                    <div v-for="file of files" :key="file.id" @click.stop>
                        <button v-if="!file.uploaded" @click="removeFile(file)" class="btn btn-sm s-circle m-2">
                            <i class="fa-solid fa-xmark fa-lg"></i>
                        </button>
                        <img v-if="file.img" :src="file.url" :alt="file.file.name" @error="file.img = false" />
                        <div v-else class="is-file">
                            <div>
                                <i class="fa-solid fa-file"></i>
                                <div class="mt-2">{{ file.file.name }}</div>
                            </div>
                        </div>

                        <div class="loading-lg" :class="{ loading: file.uploading }"></div>
                        <div v-if="file.uploaded !== null" :class="['upload', { error: !file.uploaded }]">
                            <i class="fa-solid" :class="[file.uploaded ? 'fa-check' : 'fa-exclamation-triangle']"></i>
                        </div>
                    </div>
                </div>
            </FilesDropper>
        </div>

        <div class="form-group" :class="{ ghost: !paused }">
            <input v-model="comment" id="comment" class="form-input" :placeholder="$t('upload.comment')" />
        </div>

        <div class="form-group" :class="{ ghost: !paused }">
            <label class="form-checkbox">
                <input type="checkbox" v-model="include_in_share" />
                <i class="form-icon"></i>
                <span>{{ $t('upload.include_in_share') }}</span>
            </label>
        </div>

        <template #footer>
            <SaveButton @click="upload()" :disabled="!filesToUpload.length">{{ $t('files.upload') }}</SaveButton>
            <button class="btn ml-2" v-if="!paused" @click="paused = true">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import FilesDropper from '~/composition/FilesDropper.vue'

class UploadableFile {
    constructor(file) {
        this.img = true
        this.file = file
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
        this.url = URL.createObjectURL(file)
        this.uploading = false
        this.uploaded = null
    }
}

export default {
    components: { FilesDropper },

    data() {
        return {
            files: [],
            paused: true,
            id: this.$route.params.id,

            comment: '',
            include_in_share: true,

            accept: '.jpg, .jpeg, .png, .webp, .mp4, .xlsx, .xls, .csv, .pdf, .docx'
        }
    },

    computed: {
        filesToUpload() {
            return this.files.filter(file => file.uploaded === null)
        },

        allFilesUploaded() {
            return this.files.every(file => file.uploaded)
        }
    },

    methods: {
        addFiles(newFiles) {
            const files = [...newFiles]
                .map((file) => new UploadableFile(file))
                .filter((file) => !this.fileExists(file.id))

            this.files = this.files.concat(files)
        },

        fileExists(fileId) {
            return this.files.some(({ id }) => id === fileId)
        },

        onInputChange(e) {
            this.addFiles(e.target.files)
            e.target.value = null
        },

        removeFile(file) {
            this.files.splice(this.files.indexOf(file), 1)
        },

        async upload() {
            this.paused = false

            for (const file of this.filesToUpload) {
                if (this.paused) return
                await this.uploadFile(file)
            }

            this.$emit('refresh')

            // when there is an error, stay on the upload page
            // redirect to the file show page when all files are uploaded
            if (this.allFilesUploaded) this.$router.push({ name: 'files.show' })
        },

        async uploadFile(file) {
            if (file.uploading) return

            let formData = new FormData()

            formData.append('file_id', this.id)
            formData.append('comment', this.comment)
            formData.append('include_in_share', this.include_in_share ? 1 : 0)
            formData.append('uploads[0]', file.file)

            file.uploading = true

            try {
                await this.$axios.post('uploads', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

                file.uploaded = true
            } catch {
                file.uploaded = false
            }

            file.uploading = false
        }
    },
}
</script>