<template>
    <div class="context-bar" :class="{ 'has-search': hasSearch }">
        <div class="context-left">
            <router-link v-if="backRoute" class="btn btn-sm s-circle" :to="{ name: backRoute }" exact>
                <i class="fa-solid fa-arrow-left"></i>
            </router-link>
            <slot name="title">
                <h2>{{ title }}</h2>
            </slot>
            <router-link v-if="showButtonForRoute" class="btn btn-primary" :to="{ name: buttonRoute }">
                <i class="fa-solid fa-plus-circle mr-2"></i>
                <span>{{ buttonText }}</span>
            </router-link>
        </div>

        <div class="context-right">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: [String, Number],
        backRoute: String,
        buttonText: String,
        buttonAbility: String,
        buttonRoute: String,
    },

    computed: {
        hasSearch() {
            return !!this.$slots.default
        },

        showButtonForRoute() {
            if (!this.buttonRoute) return false
            if (!this.buttonAbility) return true

            return this.$store.getters.can(this.buttonAbility)
        },
    },

    created() {
        document.title = `${this.title} | Cargosnap`
    },
}
</script>
