<template>
    <VueSelect :label="label" :options="filteredOptions">
        <template #selected-option="option">
            <Avatar v-if="avatar" :initials="option[label]" class="avatar-sm"></Avatar>
            <div>{{ option[label] }}</div>
        </template>
        <template #option="option">
            <div class="flex-space" style="width: fit-content">
                <Avatar v-if="avatar" :initials="option[label]" class="avatar-sm mr-2"></Avatar>
                <div>{{ option[label] }}</div>
            </div>
        </template>
        <template #no-options>{{ $t('fields.select_no_options') }}</template>
    </VueSelect>
</template>

<script>
import VueSelect from 'vue-select'
import Avatar from '~/components/Avatar.vue'
import MultiSelectOpenIcon from './MultiSelectOpenIcon.vue'
import MultiSelectCloseIcon from './MultiSelectCloseIcon.vue'

VueSelect.props.components.default = () => ({
    Deselect: MultiSelectCloseIcon,
    OpenIndicator: MultiSelectOpenIcon,
})

export default {
    props: {
        label: {
            type: String,
            default: 'label',
        },
        avatar: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        }
    },

    components: { VueSelect, Avatar },

    computed: {
        filteredOptions() {
            return this.options.filter(Boolean)
        }
    },
}
</script>