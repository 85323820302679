<template>
    <Modal :title="$t('context.create_files')" @close="$router.push({ name: 'files' })">
        <div class="form-group">
            <label class="form-label" for="location">{{ $t('main.location') }}</label>
            <MultiSelect v-model="location_id" label="location" inputId="location" :options="locations"
                :reduce="location => location.id" :placeholder="$t('main.location')" :clearable="false">
            </MultiSelect>
        </div>

        <div class="form-group" :class="{ 'has-error': errors }">
            <label class="form-label" for="reference">{{ $t('files.reference') }}</label>
            <input v-model="reference" ref="input" id="reference" class="form-input"
                :placeholder="$t('files.reference')" @keyup.enter="save()" />

            <div class="form-input-hint">{{ errors?.data.message }}</div>
        </div>

        <template #footer>
            <div v-if="referencesToBeCreated.length > 1" class="mb-6">
                <span class="chip chip-lg mr-1" v-for="reference in referencesToBeCreated">{{ reference }}</span>
            </div>

            <button class="btn btn-primary" :class="{ loading }" @click="save()"
                :disabled="!referencesToBeCreated.length">
                {{ $t('files.create_files', referencesToBeCreated.length) }}
            </button>
        </template>
    </Modal>
</template>

<script>
export default {
    emits: ['refresh'],

    data() {
        return {
            errors: null,
            loading: false,

            locations: [],
            reference: '',
            location_id: this.$store.state.user.location_id,
        }
    },

    computed: {
        referencesToBeCreated() {
            if (!this.reference) return []

            const referenceRegex = /(.*)#(\d+)-(\d+)/.exec(this.reference)

            if (referenceRegex) {
                const prefix = referenceRegex[1]
                const end = parseInt(referenceRegex[3])
                const start = parseInt(referenceRegex[2])

                if (end - start <= 20) {
                    return Array.from({ length: end - start + 1 }, (_, i) => `${prefix}${start + i}`)
                }
            }

            return [this.reference]
        },
    },

    async mounted() {
        this.$refs.input.focus()
        await this.fetchLocations()
    },

    methods: {
        async fetchLocations() {
            const { data: paginator } = await this.$axios.get('locations', {
                params: { sort: 'location', by: 'asc', limit: 200 },
            })

            this.locations = paginator.data
        },

        async save() {
            if (this.loading) return

            this.errors = null
            this.loading = true

            try {
                for (const scan_code of this.referencesToBeCreated) {
                    const { data } = await this.$axios.post(`files`, { scan_code, location_id: this.location_id })

                    if (this.referencesToBeCreated.length === 1) {
                        this.$router.push({ name: 'files.show', params: { id: data.id } })
                        window.toast(this.$root.$t('i.create', { i: scan_code }))

                        return
                    }
                }

                this.$emit('refresh')
                this.$router.push({ name: 'files' })

            } catch ({ response }) {
                this.errors = response

                if (response.data.file) {
                    this.errors = { data: { message: response.data.file[0] } }
                }
            }

            this.loading = false
        }
    }
}
</script>
