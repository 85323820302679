<template>
    <Modal :title="$t('files.email_all_images')" @close="back()">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>
        <div class="form-group" :class="{ 'has-error': v$.email.$error }">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="email" id="email" class="form-input" />

            <div v-if="v$.email.$error" class="form-input-hint">{{ $t('auth.email_address_must_be_valid') }}</div>
        </div>

        <template #footer v-if="!sent">
            <SaveButton @click="sendImages()">{{ $t('main.send') }}</SaveButton>
        </template>
    </Modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            sent: false,
            errors: null,
            email: this.$store.state.emailImagesTo,

            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,
        }
    },

    validations() {
        return {
            email: { required, email },
        }
    },

    methods: {
        back() {
            if (this.$route.params.id) {
                this.$router.push({ name: 'files.show' })
            } else {
                this.$router.push({ name: 'files' })
            }
        },

        async sendImages() {
            if (!await this.v$.$validate()) return

            this.errors = null

            try {
                const { data } = await this.$axios.post(`files/send_images`, {
                    files: this.ids, email: this.email, timezone: dayjs().format('Z'),
                })

                this.sent = true
                this.$store.commit('SET_EMAIL_IMAGES_TO', this.email)
                window.toast(this.$root.$t('files.send_by_email') + ': ' + this.email)
                this.$emit('refresh')
                this.back()

            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
