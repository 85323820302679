<template>
    <WatchForUnsavedChanges ref="watch" v-model="hook" v-slot="{ hasChanges }">
        <Spinner v-if="id && !hook.id"></Spinner>
        <SidePanel v-else :title="id ? $t('webhooks.edit') : $t('context.create_webhooks')"
            @close="$router.push({ name: 'settings.webhooks' })">
            <form v-if="$store.state.user.properties.use_api" @submit.prevent="saveWebhook()">
                <div class="form-group">
                    <Errors :errors="errors" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="description">{{ $t('webhooks.description') }}</label>
                    <input id="description" class="form-input" v-model="hook.description"
                        :placeholder="$t('webhooks.description')" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="action">{{ $t('webhooks.action') }}</label>
                    <select id="action" v-model="hook.action" class="form-select" required>
                        <option :value="action" v-for="action in actions" :key="action">
                            {{ $t(`webhooks.${action}`) }}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="form-label" for="url">{{ $t('webhooks.url') }}</label>
                    <input type="url" id="url" class="form-input" v-model="hook.public_url"
                        placeholder="https://webhook.url" required />
                </div>

                <div class="form-group">
                    <label class="form-label" for="email_on_failure">{{ $t('webhooks.email_on_failure') }}</label>
                    <input type="email" class="form-input" id="email_on_failure" v-model="hook.email_on_failure"
                        :placeholder="$t('webhooks.emailcsv')" required />
                </div>

                <div class="dev-feature" v-if="$store.getters.can('Use internal features')">
                    <div class="form-group">
                        <label class="form-label" for="scope">Scope</label>
                        <input type="number" id="scope" class="form-input" v-model="hook.scope" />
                    </div>
                </div>

                <div class="flex-space">
                    <button class="btn btn-primary" type="submit" :disabled="!hasChanges">
                        {{ id ? $t('main.save') : $t('main.create') }}
                    </button>
                    <button v-if="id" type="button" class="btn btn-error" @click="deleteWebhook()">
                        {{ $t('main.delete') }}
                    </button>
                </div>
            </form>
            <NeedsPremiumLicense v-else :text="$t('license_limit.api_tokens')"
                :limit-text="$t('license_limit.premium_feature')"></NeedsPremiumLicense>
        </SidePanel>
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    emits: ['refresh'],
    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            errors: null,
            id: this.$route.params.id,

            hook: {
                url: '',
                description: '',
                email_on_failure: '',
                action: 'NEW_FILE_CREATED',
            },

            actions: [
                'NEW_FILE_CREATED',
                'SUBMIT_FORM',
                'WORKFLOW_RUN_COMPLETE',
                'SHARE_CREATED',
                'FIELD_VALIDATION_FAILED',
                'FILE_CLOSED',
            ],
        }
    },

    async mounted() {
        if (this.id) await this.fetchData()
        this.$refs.watch?.init()
    },

    methods: {
        async fetchData() {
            const { data: hook } = await this.$axios.get(`webhooks/${this.id}`)

            this.hook = hook
        },

        async saveWebhook() {
            this.errors = null

            try {
                this.id ?
                    await this.$axios.post(`webhooks/${this.hook.id}/edit`, this.hook) :
                    await this.$axios.post('webhooks', this.hook)
            } catch ({ response }) {
                this.errors = response
            }

            this.$refs.watch?.init()
            this.$router.push({ name: 'settings.webhooks' })
            this.$emit('refresh')
        },

        deleteWebhook() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`webhooks/${this.hook.id}`).then(() => {
                        this.$router.push({ name: 'settings.webhooks' })
                        this.$emit('refresh')
                    })
                }
            })
        },
    },
}
</script>
