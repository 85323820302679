<template>
    <div :id="`upload-${item.id}`" class="timeline-item">
        <div class="timeline-left">
            <a :href="`#upload-${item.id}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t(`timeline.attachment`, { by: item.device_nick }) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-sm-12">
                        <div class="flex-start">
                            <figure class="avatar avatar-lg flex-space mr-2">
                                <i class="fas fa-file p-centered"></i>
                            </figure>
                            <div>
                                <a :href="item.image_url" target="_blank">{{ item.image_name }}</a>
                                <div class="text-gray text-small">
                                    <span>{{ humanFileSize(item.image_size) }} · </span>
                                    <DateTime :date="item.created_at"></DateTime>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-snap btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <button @click="$emit('edit', item)" class="edit-snap btn mr-2">
                                <i class="fas fa-pencil"></i>
                            </button>

                            <a :href="item.image_url" target="_blank" class="btn mr-2">
                                <i class="far fa-circle-down"></i>
                            </a>

                            <button @click="$emit('share', item)" class="edit-snap btn">
                                <i class="fas fa-share-alt" :class="{ 'text-gray': !item.include_in_share }"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],

    methods: {
        humanFileSize(bytes) {
            const i = Math.floor(Math.log(bytes) / Math.log(1024))
            return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ['B', 'kb', 'mb'][i]
        },
    },
}
</script>
