<template>
    <div class="text-center">
        <figure class="avatar avatar-xl bg-linen text-dark" style="margin: 1.2rem 0">
            <i class="fa-solid fa-box-open fa-sm"></i>
        </figure>

        <h4 class="text-bold mb-4">
            <span v-if="params.length">{{ $t('main.no_data_found_by_search', { search: null }) }}</span>
            <span v-else>{{ $t('main.no_data_found') }}</span>
        </h4>

        <div class="chip bg-gray mr-2" v-for="param in params">
            <span class="text-bold px-2">{{ param.key }}:</span>
            <span>{{ param.value }}</span>
            <a href="#" class="btn btn-clear" @click.prevent="$emit('removeParameter', param)"></a>
        </div>

        <div v-if="params.length > 1" class="mt-4">
            <a href="#" @click.prevent="$emit('clearParameters')">{{ $t('table.clear_filters') }}</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        params: {
            type: Array,
            required: true,
        },
    },

    emits: ['removeParameter', 'clearParameters'],
}
</script>
